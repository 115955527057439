import axios from "axios";
import { Module } from "vuex";

const VUE_APP_PAYMENTS_API_V1_URL = process.env.VUE_APP_PAYMENTS_API_V1_URL;

type PaymentVendorState = {
  vendors: {
    data: any[];
    total: number;
  };
};

const paymentVendorModule: Module<PaymentVendorState, unknown> = {
  namespaced: true,
  state: () => ({
    vendors: {
      data: [],
      total: 0,
    },
  }),
  getters: {
    paymentVendors: (state) => state.vendors,
    getMyStripeAcc: (state) => (vendorId: string) =>
      state.vendors.data.filter(
        (data) => data.user_id == vendorId && data.account_type == "stripe"
      )[0],
  },
  mutations: {
    SET_VENDOR_PAYMENTS: (state, _data) => {
      state.vendors = _data;
    },
  },
  actions: {
    fetchPaymentVendorList(context, country = "Kenya") {
      axios
        .get(`${VUE_APP_PAYMENTS_API_V1_URL}/paymentVendor`)
        .then((response) => {
          context.commit("SET_VENDOR_PAYMENTS", response.data);
        });
    },
  },
};

export default paymentVendorModule;
