import { api } from "./axios";

export const shippingMethods = (business: string): Promise<any> => {
  return api.get(`/v1/shipping-methods?businessId=${business}`);
};

export const addShippingMethod = (data: any): Promise<any> => {
  return api.post("/v1/shipping-methods", { ...data });
};

export const deleteShippingMethod = (id: string): Promise<any> => {
  return api.delete(`/v1/shipping-methods/${id}`);
};
export const updateShippingMethod = (data: any): Promise<any> => {
  const { id, ...payload } = data;
  return api.put(`/v1/shipping-methods/${id}`, { ...payload });
};

interface ValidateCart {
  business: string;
  cart_total: number;
  products: any[];
  address: any;
}

export const validateShippingCart = (data: ValidateCart): Promise<any> => {
  return api.post("/v1/shipping-methods/validate-cart", { ...data });
};
